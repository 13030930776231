html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: GuardianSans-Light-Web;
  src: url(~src/assets/fonts/guardiansans-light-web.woff);
}

@font-face {
  font-family: GuardianSans-Medium-Web;
  src: url(~src/assets/fonts/guardiansans-medium-web.woff);
}

@font-face {
  font-family: GuardianSans-Regular-Web;
  src: url(~src/assets/fonts/guardiansans-regular-web.woff);
}

@font-face {
  font-family: GuardianSans-Thin-Web;
  src: url(~src/assets/fonts/guardiansans-thin-web.woff);
}

[class*='f1-icon-']:before {
  display: inline-block;
  font-family: 'f1-icon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.f1-icon {
  height: 1.2em;
  width: 1.2em;
  font-size: 1.2em;
  font-style: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

body {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: stretch;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-app {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 112px;
  background: url("~src/assets/img/login-background.png") 0 0/cover no-repeat;
  height: 100%;
}

.login-section {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.login-section .login-text-header {
  font-family: GuardianSans-Thin-Web, serif;
  font-size: 3em;
  color: #ffffff;
}

.login-section .login-text-header span {
  font-family: GuardianSans-Light-Web, serif;
}

.login-section .login-text-info {
  font-family: GuardianSans-Light-Web, serif;
  font-size: 1.2em;
  color: #ffffff;
}

.login-section .f1-sense-time-logo {
  font-size: 8em;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  background: url("~src/assets/img/sencetime-logo.svg");
}

.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  height: 35px;
  bottom: -35px;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  right: 30px;
  border-top: 29px solid rgba(0, 0, 0, 0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-right: 30px solid transparent;
  border-left: none;
}

.login-form {
  width: 340px;
  height: 530px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 30px 0 30px;
  background: #ffffff;
  color: #053040;
  position: relative;
}

.login-form .login-form-container {
  position: relative;
  height: 100%;
}

.login-form .form-field-label {
  padding-bottom: 30px;
}

.login-form .form-field-label .menu-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.login-form .form-field-label .menu-header .burger-menu {
  height: 30px;
  width: 30px;
  margin-left: -10px;
  background: url("~src/assets/img/burger-menu-open.svg");
}

.login-form .form-field-label .menu-header .app-logo {
  height: 80px;
  width: 198px;
  background: url("~src/assets/img/sensehub-logo.svg");
}

.login-form .form-field-label .menu-header .brand span:first-child {
  font-family: GuardianSans-Medium-Web, serif;
  font-weight: bold;
  font-size: 1.7em;
  color: #58595b;
}

.login-form .form-field-label .menu-header .brand span:last-child {
  font-family: GuardianSans-Regular-Web, serif;
  font-weight: bold;
  color: #1999d5;
}

.login-form .form-field-title {
  padding: 15px 0;
  font-family: GuardianSans-Medium-Web, serif;
  color: #1999D6;
}

.login-form .form-field {
  display: flex;
}

.login-form .form-field [disabled] {
  color: #A8AAAD;
}

.login-form .form-field label {
  display: flex;
  align-items: center;
  width: 150px;
}

.login-form .form-field input {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px 20px;
  font-size: 1.3em;
  outline: none;
  border: none;
  color: #59595C;
  font-family: GuardianSans-Light-Web, serif;
  background-color: #f4f4f4;
}

.login-form .form-field.form-field-error {
  padding: 15px 0;
}

.login-form .form-field.form-field-error label {
  font-family: GuardianSans-Light-Web, serif;
  color: red;
  width: 450px;
}

.login-form .form-field.form-field-btn {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.login-form input[type='submit'] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-size: 1.2em;
  background: #ffffff;
  cursor: pointer;
  font-family: GuardianSans-Light-Web, serif;
  color: #1999D6;
  border: 1px solid #1999D6;
}

.login-form input[type='submit'].login-btn {
  font-family: GuardianSans-Regular-Web, serif;
  color: #ffffff;
  background-color: #1999D6;
}

.login-form .form-field.remember-me {
  display: flex;
  align-items: center;
  font-family: GuardianSans-Light-Web, serif;
  cursor: pointer;
  padding-bottom: 0 !important;
}

.login-form .form-field.remember-me label{
  margin-left: 5px;
  cursor: pointer;
}

.login-form .form-field.remember-me .f1-icon{
  font-size: 0.9em;
}

.login-form .form-field.remember-me .f1-icon.f1-icon-un-check-box {
  background: url('~src/assets/img/uncheck-box.svg');
}

.login-form .form-field.remember-me.selected .f1-icon.f1-icon-un-check-box {
  background: url('~src/assets/img/check-box.svg');
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.3) url("~src/assets/img/spinners/loading.gif") no-repeat center;
}

.register-section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 112px;
  flex-direction: column;
  background: url("~src/assets/img/login-background.png") 0 0/cover no-repeat;
  height: 100%;
  justify-content: center;
}

.register-section .f1-sense-time-logo {
  font-size: 8em;
  display: flex;
  justify-content: center;
  text-align: center;
  background: url("~src/assets/img/sencetime-logo.svg");
}

.register-section .title {
  font-family: GuardianSans-Regular-Web, serif;
  font-size: 60px;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 24px;
}

.register-section .content {
  font-family: GuardianSans-Light-Web, serif;
  font-size: 24px;
  color: #fff;
  text-align: center;
}
